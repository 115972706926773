@import "../../../baseTheme/settings";

.single-post {
    margin-bottom: 72px;

    @include media-breakpoint-down(md) {
        margin-bottom: 0;
    }
    picture {
        border-radius: 12px;
        overflow: hidden;
        display: block;

        img {
            width: 100%;
        }
    }

    .single-content-area {

        h2,
        h1, h3, h4, h5, h6, p {
            margin-bottom: 12px;
            @include primaryFont(400);
        }
    }

    .btn-link {
        font-family: var(--skin-body-font-book);
        color: $plum !important;
        border-color: $plum !important;
    }
}

.experience-commerce_assets-singlePost {
    width: 30%;
    margin-right: 5%;

    &:last-child {
        margin-right: 0;
    }

    @include media-breakpoint-down(md) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 32px;
    }
}